<template>
  <div class="page-main">
    <b-container class="static-page">
      <div v-html="page.html[locale]"></div>
    </b-container>
  </div>
</template>
<script>
export default {
  props: ["link"],
  computed: {
    page() {
      return this.pages.find(page => page.link === this.link)
    },
    pages() {
      return this.$store.state.Pages.pages
    },
    locale() {
      return this.$root.$i18n.locale;
    }
  }
}
</script>
<style lang="scss">
.center { 
  text-align: center;
}
.page-main {
  flex-grow: 1;
  // background-color: white;
}
.static-page { 
  padding: 25px;
  background-color: rgb(0 0 0 / 40%);
}
.ql-align-center {
  text-align: center;
}
</style>